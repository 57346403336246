import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import Header from './Header';
import FeaturedLogos from './FeaturedLogos';
import Footer from './Footer';
import Cart from './Cart';
import { StoreContext } from '../context/StoreContext';

const Layout = ({ children, isHomepage }) => {
  const {
    datoCmsSite: { faviconMetaTags },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  const { isOverlayActive } = useContext(StoreContext);

  return (
    <>
      <HelmetDatoCms favicon={faviconMetaTags}>
        <html lang="en-GB" />
        <meta name="google-site-verification" content="sQrqqXrVOlXzXtR-j0Zg0awZwlGSwTUQxdd8sThVhSI" />
      </HelmetDatoCms>
      <GlobalStyle isOverlayActive={isOverlayActive} />
      <Header />
      {children}
      {!isHomepage && <FeaturedLogos />}
      <Footer />
      <Cart />
    </>
  );
};

export default Layout;
